<template>
    <div class="container">
        <div class="column">
            <div class="columns">
                <div v-show="!isMobile" class="columns  pt-3">
                    <div class="column  mr-0 pr-0">
                        <button class="button is-static has-text-black" >Mostrar</button>
                    </div>
                    <div class="column  pl-0 control" v-tooltip="'Filas'">
                        <div class="select ">
                            <select class="options" v-model="mostrar_cantidad">
                                <option value="50" >50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-show="isMobile" class="columns has-text-right is-mobile pt-3">
                    <div class="column  mr-0 pr-0 has-text-right">
                        <button class="button is-static has-text-black">Mostrar</button>
                    </div>
                    <div class="column  has-text-left control">
                        <div class="select ">
                            <select class="options" v-model="mostrar_cantidad">

                                <option value="50" >50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-show="!isMobile"  class="column  has-text-center">
                    <!-- Buscador -->
                    <div class=" field ">
                        <p class="control has-icons-left " style="width:20.8rem">
                            <input class="input " type="text" placeholder="Buscar punto wifi" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div v-show="isMobile"  class="column  has-text-center ml-3">
                    <!-- Buscador -->
                    <div class=" field ">
                        <p class="control has-icons-left w-100">
                            <input class="input " type="text" placeholder="Buscar punto wifi" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div class="column is-5">
                    <div class="buttons is-justify-content-flex-end">
                        <div class="dropdown pr-2" :class="{'is-active':mas_opciones}" >
                            <div id="mas-opciones" class="dropdown-trigger">
                                <button id="mas-opciones" class="button has-background-info has-text-white" aria-haspopup="true" aria-controls="dropdown-menu" @click="mas_opciones = !mas_opciones">
                                    <span id="mas-opciones">Mas opciones</span>
                                    <span id="mas-opciones" class="icon is-small">
                                        <i id="mas-opciones" class="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu pt-0" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">
                                    <a id="mas-opciones" class="dropdown-item" @click="eliminar_adv_masivo">
                                        Eliminación masiva
                                    </a>
                                </div>
                            </div>
                        </div>
                        <button class="button has-background-info has-text-white" @click="filtro">Filtro</button>
                        <div>
                            <div class="modal" :class="{'is-active': filtrar}">
                                <div id="modal-background" class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width">
                                    <div class="container has-text-centered has-background-white p-3"  id="modal">
                                        <div class="columns">
                                            <div class="column is-9 pb-0" :class="{'ml-5':!isMobile}">
                                                <h1 class="is-size-3 has-text-black has-text-weight-bold pt-3">Filtrar tabla</h1>
                                            </div>
                                            <div class="column" :class="{'mt-3 pr-1':!isMobile}">
                                                <button class="button verde-puwic mb-0 has-text-white has-text-weight-bold" @click="limpiarFiltro">Limpiar</button>
                                            </div>
                                        </div>
                                        <div :class="{'modal-scrolleable' : isMobile}">
                                            <!-- ID -->
                                            <div class="field columns mt-2" :class="{ 'mb-4' : !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-0" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">ID</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" style="width: 139px">
                                                            <select class="options" v-model="tipo_filtro.id" style="width: 139px">
                                                                <option value="es">es</option>
                                                                <option value="mayor">mayor a</option>
                                                                <option value="menor">menor a</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.id" name="id" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Nombre -->
                                            <div class="field columns" :class="{'mb-0 pt-1' : isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2">
                                                    <label class="label" :class="{'has-text-right': !isMobile}" >Nombre</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1' : isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.nombre">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1' : isMobile}">
                                                        <input class="input" v-model="datos_filtro.nombre" name="nombre" type="text">
                                                    </div>

                                                </div>
                                            </div>
                                            <!-- Domicilio -->
                                            <div class="field columns mt-0" :class="{'mb-0': isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Domicilio</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.domicilio">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                                <option value="vacio">vacio</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.domicilio" name="domicilio" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Ciudad -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Ciudad</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.ciudad">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.ciudad" name="ciudad" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Precio por día -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-0" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Precio por día</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" style="width: 139px">
                                                            <select class="options" v-model="tipo_filtro.precioxdia" style="width: 139px">
                                                                <option value="es">es</option>
                                                                <option value="mayor">mayor a</option>
                                                                <option value="menor">menor a</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.precioxdia" name="precioxdia" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Comentario -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mt-0': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Comentario</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.comentario">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                                <option value="vacio">vacio</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.comentario" name="ciudad" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Botones -->
                                            <div class="columns">
                                                <div class="column">
                                                    <div class="buttons">
                                                        <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 30%" @click="filtrar = false">Cancelar</button>
                                                        <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 66%" @click="filtro_avanzado">Filtro</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link :to="{name: 'NuevoPuntoWifi'}" class="button has-background-link has-text-white">Agregar punto wifi</router-link> -->
                        <a @click="verificarHabilitado('NuevoPuntoWifi')" class="button has-background-link has-text-white">Agregar punto wifi</a>
                    </div>
                </div>
            </div>
        </div>

        <button class="total-element">Total: {{AllpuntoWifi.length}}</button>
        <button v-show="filtrado" class="total-element mx-0 pointer" @click="filtro">Total filtrado: {{totalElement}}</button>

        <div class="column table-container">
            <table class="table  has-background-white-ter" style="width: 100%">
                <thead>
                    <tr>
                    <th>
                        <div v-show="todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                <span class="icon-text">
                                    <span class="icon is-size-6">
                                        <i class="far fa-check-square"></i>
                                    </span>
                                </span>
                            </button>
                        </div>
                        <div v-show="!todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                <span class="icon-text">
                                    <span class="icon is-size-6">
                                        <i class="far fa-square"></i>
                                    </span>
                                </span>
                            </button>
                        </div>
                        <!-- <input @click="tildar(punto)" type="checkbox" disabled> -->
                    </th>
                    <th>
                        <button @click="ordenar('id')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            ID
                            <div  v-show="clics.n1!=false">
                                <span  v-show="ordenarId!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarId==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('nombre')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Nombre
                            <div  v-show="clics.n2!=false">
                                <span  v-show="ordenarNombre!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarNombre==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('domicilio')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Domicilio
                            <div  v-show="clics.n3!=false">
                                <span  v-show="ordenarDomicilio!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarDomicilio==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>

                    </th>
                    <th>
                        <button @click="ordenar('ciudad')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Ciudad
                            <div  v-show="clics.n4!=false">
                                <span  v-show="ordenarCiudad!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCiudad==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('anuncios_totales')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Anuncios totales
                            <div  v-show="clics.n7!=false">
                                <span  v-show="ordenarAnuncios!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarAnuncios==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <!-- <th>
                        <button  class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Coordenadas
                        </button>
                    </th> -->
                    <th>
                        <button @click="ordenar('precioxdia')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Precio por día
                            <div  v-show="clics.n5!=false">
                                <span  v-show="ordenarPrecio!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarPrecio==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('comentario')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Comentario
                            <div  v-show="clics.n6!=false">
                                <span  v-show="ordenarComentario!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarComentario==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>

                    </tr>
                </thead>

                <tbody >
                    <tr v-for="(punto,index) in puntoWifi" :index="index" :key="punto.nombre" :class="{'Color_sin_anuncio':punto.anuncio.length==0,'fondo-fila':index%2==0 && !punto.tildado,'seleccionado': punto.tildado, 'deseleccionado': !punto.tildado}">
                        <td>
                            <input v-if="!punto.tildado" @click="tildar(punto)" class="ml-2" type="checkbox">
                            <input v-else @click="tildar(punto)" class="ml-2" checked type="checkbox">
                        </td>
                        <td @click="modal(punto)">{{punto.id}}</td>
                        <td @click="modal(punto)">{{punto.nombre}}</td>
                        <td @click="modal(punto)">{{punto.domicilio}}</td>
                        <td @click="modal(punto)">{{punto.ciudad}}</td>
                        <td @click="modal(punto)">{{punto.anuncio.length}}</td>
                        <td @click="modal(punto)">{{punto.precioPorDia}}</td>
                        <td v-if="punto.comentario != 'null'" @click="modal(punto)">{{punto.comentario}}</td>
                        <td v-else @click="modal(punto)"></td>

                        <div>
                            <div class="modal" :class="{'is-active': punto.activo}" @click="punto.activo = false">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                    <div class="modal-content-width" style="border:1px ridge grey;">
                                        <div class="container has-text-centered has-background-white" id="modal">
                                            <div class="column p-0 pt-4 px-4">
                                                <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="punto.configuracion = true; copiado_text = 'Copiar Script'">Código de activación</button>
                                            </div>
                                            <div class="column p-0 pt-1 px-4">
                                                <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="punto.ver_anuncios = true">Ver anuncios</button>
                                            </div>
                                            <div class="column p-0 py-1 px-4">
                                                <!-- <router-link :to="{name: 'EditarPuntoWifi', params: {id: punto.id}}" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</router-link> -->
                                                <a @click="verificarHabilitadoParam('EditarPuntoWifi',punto.id)" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</a>
                                            </div>
                                            <div class="column p-0 pb-4 px-4">
                                                <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="eliminar_adv(punto)">Eliminar</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <!-- Modal de configuraciones -->
                        <div class="modal is-mobile" :class="{'is-active': punto.configuracion}">
                            <div class="modal-background " style="border:1px ridge grey;"></div>
                            <div class="modal-card">
                                <header class="modal-card-head">
                                    <p class="modal-card-title is-size-6-mobile">Activar punto wifi para PuWiC</p>
                                    <button class="delete" aria-label="close" @click="punto.configuracion = false"></button>
                                </header>
                                <section class="modal-card-body" >
                                    <div class="field columns">
                                        <div class="control column  has-text-left is-flex-grow-1">
                                            <textarea v-model="punto.hash" class="textarea" readonly="readonly" style="height: 200px"></textarea>
                                        </div>
                                    </div>
                                </section>
                                <!-- <footer class="modal-card-foot">
                                    <button
                                        v-clipboard:copy="value" v-clipboard:success="onSuccess"
                                        class="button verde-puwic has-text-white has-text-weight-bold"
                                        style="width: 100%">{{copiado_text}}
                                    </button>
                                </footer> -->
                            </div>
                        </div>


                        <!-- Modal de anuncios -->
                        <div>
                            <div class="modal" :class="{'is-active': punto.ver_anuncios}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}" >
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">Anuncios en este punto</h1>
                                        <div v-if="punto.anuncio.length > 0" class="my-5 ml-5 pl-5">
                                            <ul v-for="item in punto.anuncio" :key="item.nombre" class="ml-4">
                                                <li class="has-text-weight-semibold has-text-left ml-5 pl-1 circulo">{{item.nombre}}</li>
                                            </ul>
                                        </div>
                                        <div v-else class="my-5 ">
                                            <p class="has-text-centered has-text-grey-dark">No hay anuncios en este punto</p>
                                        </div>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="punto.ver_anuncios = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <!-- Modal de limite de puntos wifi -->
                        <div>
                            <div class="modal" :class="{'is-active': mostrar_error}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1); "></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">No se puede eliminar el punto wifi, porque tiene Anuncios relacionados</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_error = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div class="modal" :class="{'is-active': mostrar_advertencia}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">No se puede eliminar el punto wifi, porque tiene Anuncios relacionados</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_advertencia = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="modal" :class="{'is-active': mostrar_advertencia2}" >
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white px-1 py-1" style="width: 320px" id="modal">
                                        <h1 class="is-size-3 has-text-weight-semibold has-text-danger" :class="{'is-size-4':isMobile}">¿Estas seguro?</h1>

                                        <p v-show="opsMasivo==false" class="has-text-centered ">Quiere eliminar el punto wifi {{punto_elegido.nombre}}. Esta acción no se podra revertir</p>
                                        <p v-show="opsMasivo==true" class="has-text-centered ">Se van eliminar los puntos wifi seleccionados. Esta accion no se puede revertir.</p>
                                        <div class="columns   py-1 pt-4 px-1">
                                            <div class="column ">
                                                <button class="button w-100 has-background-danger is-outline btn has-text-white has-text-weight-blod" @click="cancelar">Cancelar</button>
                                            </div>
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="confirmar">Si,eliminar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="modal" :class="{'is-active': mostrar_validacion}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">Se debe seleccionar algun punto wifi para realizar la accion</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_validacion = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <!-- Mensaje de que se cargo con exito -->
                        <div>
                            <div class="modal" :class="{'is-active': carga_exitosa}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p v-show="comprobar == true" class="has-text-centered has-text-success">Se cargo con exito el punto wifi.</p>
                                        <p v-show="comprobar_edi == true" class="has-text-centered has-text-success">Se edito con exito el punto wifi.</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="carga_exitosa = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="modal" :class="{'is-active': accion_exitosa}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p v-show="paso_elim==true" class="has-text-centered has-text-success">Se elimino los puntos wifi exitosamente.</p>
                                        <p v-show="paso_elim==false" class="has-text-centered has-text-danger">No se logro eliminar a los puntos wifis seleccionados.</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="accion_exitosa = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tr>
                </tbody>
            </table>

            <div v-show="preload" class="loader-wrapper is-active-loading">
                <div class="loader is-loading "></div>
            </div>

            <div v-if="!preload && puntoWifi.length == 0">
                <h1 class="has-text-centered is-size-4 has-text-grey">No hay puntos wifi</h1>
            </div>
        </div>
        <nav v-show="totalElement > mostrar_cantidad" class="pagination is-right" role="navigation" aria-label="pagination">
            <button v-if="paginaInfo.page > 1" class="pagination-previous" @click="prevPage">Anterior</button>
            <button v-else class="pagination-previous" disabled >Anterior</button>
            <button v-if="paginaInfo.page < pageCont" class="pagination-next" @click="nextPage">Siguiente</button>
            <button v-else class="pagination-next" disabled>Next</button>
            <ul class="pagination-list" style="justify-content: center">
                <li v-show="paginaInfo.prev > 1"> <button class="pagination-link" @click="inicio">1</button></li>
                <li v-show="paginaInfo.prev > 2"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="paginaInfo.prev != null"><button class="pagination-link" @click="prevPage">{{paginaInfo.prev}}</button></li>
                <li><button class="pagination-link is-current">{{paginaInfo.page}}</button></li>
                <li v-show="paginaInfo.next != null && paginaInfo.next > 1"><button class="pagination-link" @click="nextPage">{{paginaInfo.next}}</button></li>
                <li v-show="paginaInfo.next < pageCont - 1 && paginaInfo.next != null"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="paginaInfo.next != null && (paginaInfo.next < pageCont) && (pageCont != 1)" ><button class="pagination-link" @click="final">{{pageCont}}</button></li>
            </ul>
        </nav>
    </div>
</template>

<script>
import {filtrar_campo_numeral} from '@/composables/filtrar_campo_numeral.js'
import {filtrar_campo_texto} from '@/composables/filtrar_campo_texto.js'
import {ordenar_arreglo} from '@/composables/ordenar_arreglo.js'
import store from '@/store';
import { GraphQLClient } from 'graphql-request'
import { inject, ref, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import {queryMe} from '@/composables/queryMe.js'


export default {

    setup(){
        const mostrar_advertencia = ref(false)
        const mostrar_nombre = ref('')
        const mostrar_error = ref(false)
        const todo = ref(false)
        const isMobile = inject('isMobile')
        const mostrar_cantidad = ref(50)
        const puntoWifi = ref([])
        const puntoWifi_aux = ref([])
        const aux = ref([])
        // const value = ref('/system scheduler add interval=10s name=task0 on-event=":local getversion ([:toarray[/tool fetch mode=https http-method=post url=\"https://ynriver.com/hotspots_v1/logica_v1_mik.php\\\?get_version=0\" output=user as-value]]->\"data\");\r\ \n:if (\$getversion != \"ok\") do={\r\ \n\t/tool fetch mode=https http-method=post')
        const value = ref(`#Habilitación de punto PuWiC en Miktrotik \n/file set contents="var imgr = 'hash';" puwic/rotative.js`)
        const value2 = ref(`\n#Habilitación de punto PuWiC en Miktrotik con carpeta flash \n/file set contents="var imgr = 'hash';" flash/puwic/rotative.js`)
        const copiado = ref(false)
        const copiado_text = ref('Copiar script')
        const filtrar = ref(false)
        const datos_filtro = ref({id: '', nombre: '', domicilio: '', ciudad: '', latitud: '', longitud: '', precioxdia: '', correo: '', comentario: ''})
        const tipo_filtro =  ref({id: 'es', nombre: 'contiene', domicilio: 'contiene', ciudad: 'contiene', latitud: 'es', longitud: 'es', precioxdia: 'es', correo: 'contiene', comentario: 'contiene'})
        const buscar = ref('')
        const info = ref({count:0, pages:0, next:0, prev:0, page:0, total: 0})
        const route = useRoute()
        const router = useRouter()
        const endpoint = store.state.url_backend
        const clics = ref({n1:false,n2:false,n3:false, n4:false,n5:false,n6:false,n7:false})
        const ordenarId = ref(false)
        const ordenarNombre = ref(false)
        const ordenarDomicilio = ref(false)
        const ordenarCiudad = ref(false)
        const ordenarPrecio = ref(false)
        const ordenarComentario = ref(false)

        const ordenarAnuncios = ref(false)

        const mas_opciones = ref(false)
        const eliminarPuntos = ref([])
        const AllpuntoWifi = ref([])
        const paginaInfo = ref({page: 1, pageSize: parseInt(mostrar_cantidad.value), prev: null, next: 0})
        const pageCont = ref(0) // capaz tenga que agregarlo en la consulta de datos
        const totalElement = ref(0)
        const punto_elegido = ref([{id:null,nombre:''}])
        const mostrar_advertencia2 = ref(false)
        const opsMasivo = ref(false)
        const cant_elegida = ref(null)
        const filtrado = ref(false)
        const mostrar_validacion = ref(false)
        const preload = ref(true)
        const carga_exitosa = ref(false)
        const comprobar = store.state.carga_exitosa_ciudad
        const comprobar_edi = store.state.edicion_exitosa
        const accion_exitosa = ref(false)
        const paso_elim = ref(false)


        const comprobar_carga = () => {

            // console.log(comprobar)
            if(comprobar==true){
               carga_exitosa.value = true
               let accion = "nuevopuntowifi"
               store.commit('verificar_carga',accion)
            }

        }
        const comprobar_edicion = () => {

            // console.log(comprobar)
            if(comprobar_edi==true){
               carga_exitosa.value = true
               let accion = "edicionpuntowifi"
               store.commit('verificar_carga',accion)
            }

        }

        const verificarHabilitado = async (name) => {
            let habilitado = await queryMe()
            if (habilitado) {
                router.push({name: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        const verificarHabilitadoParam = async (name, params) => {
            let habilitado = await queryMe()
            if (habilitado) {
                router.push({name: name, params: {id: params} })
            } else {
                router.push({name: 'Login'})
            }
        }

        const eliminar_adv_masivo = () => {

            let cont = 0
            puntoWifi.value.forEach(element => {
                if(element.tildado){
                        cont += 1
                }

            })

            if(cont!=0){
                mostrar_validacion.value = false

                opsMasivo.value =  true
                eliminarPuntos.value = []
                mostrar_advertencia2.value = !mostrar_advertencia2.value

            }else{
                    mostrar_validacion.value = true

            }
        }

        const eliminar_adv = (punto) => {
            opsMasivo.value = false
            mostrar_advertencia2.value = !mostrar_advertencia2.value
            punto_elegido.value.id = punto.id
            punto_elegido.value.nombre = punto.nombre

        }

        const confirmar = () => {

            mostrar_advertencia2.value = !mostrar_advertencia2.value
            if(opsMasivo.value==true){
                eliminar_masivamente()
                // console.log(eliminarPuntos.value)
            }else{
                eliminar(punto_elegido.value.id)
            }
        }

        const cancelar = () => {
            mostrar_advertencia2.value = !mostrar_advertencia2.value
        }

        const eliminar_masivamente = async () => {

                puntoWifi.value.forEach(element => {
                    if (element.tildado == true) {
                        eliminarPuntos.value.push({id: element.id})

                    }
                })

                eliminarPuntos.value.forEach(element => {
                    eliminar(element.id)
                })
        }


        watchEffect(() => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query($first:Int!){
                puntowifis(first: $first){
                    paginatorInfo{
                        count
                        currentPage
                        hasMorePages
                        lastPage
                        total
                    }
                    data{
                        id,
                        nombre,
                        domicilio,
                        latitud,
                        longitud,
                        precioxdia,
                        comentario,
                        hash,
                        municipio {
                            id,
                            nombre
                        },
                        anuncios{
                            nombre
                        }
                    }
                }
            }`,
            {
                first: 99999
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then( async (data) => {
                // console.log(data)

                await data.data.puntowifis.data.forEach(element => {
                    let hash = value.value.replace('hash', element.hash) + '\n' + value2.value.replace('hash', element.hash)
                    AllpuntoWifi.value.push({id:element.id, nombre: element.nombre, domicilio: element.domicilio, ciudad: element.municipio.nombre, anuncio: element.anuncios,
                    latitud: element.latitud + '', longitud: element.longitud + '' ,comentario: element.comentario+'',precioPorDia: element.precioxdia+ '', hash: hash, activo: false, configuracion: false, tildado: false, ver_anuncios: false})
                })

                pageCont.value = Math.ceil(AllpuntoWifi.value.length/paginaInfo.value.pageSize)

                if (pageCont.value > 1) {
                    paginaInfo.value.next = paginaInfo.value.page + 1
                }

                puntoWifi_aux.value = AllpuntoWifi.value
                showImpresiones(puntoWifi_aux.value)
                preload.value = false

            }).catch(error => {
                // console.log(error);
            })
        })

        const paginate = (array, page_size, page_number) => {
            return array.slice((page_number - 1) * page_size, page_number * page_size)
        }

        const nextPage = () => {
            paginaInfo.value.page++
            if (paginaInfo.value.page >= pageCont.value) {
                paginaInfo.value.next = null
                paginaInfo.value.prev = paginaInfo.value.page - 1
            } else {
                paginaInfo.value.next = paginaInfo.value.page + 1
                paginaInfo.value.prev = paginaInfo.value.page - 1
            }
            showImpresiones(puntoWifi_aux.value)
        }

        const prevPage = () => {
            paginaInfo.value.page--
            if (paginaInfo.value.page <= 1) {
                paginaInfo.value.prev = null
                paginaInfo.value.next = paginaInfo.value.page + 1
            } else {
                paginaInfo.value.prev = paginaInfo.value.page - 1
                paginaInfo.value.next = paginaInfo.value.page + 1
            }
            showImpresiones(puntoWifi_aux.value)
        }

        const inicio = () => {
            paginaInfo.value.page = 1
            paginaInfo.value.prev = null
            if (paginaInfo.value.page >= pageCont.value) {
                paginaInfo.value.next = null
            } else {
                paginaInfo.value.next = paginaInfo.value.page + 1
            }
            showImpresiones(puntoWifi_aux.value)
        }

        const final = () => {
            paginaInfo.value.page = pageCont.value
            paginaInfo.value.next = null
            if (paginaInfo.value.page <= 1) {
                paginaInfo.value.prev = null
            } else {
                paginaInfo.value.prev = paginaInfo.value.page - 1
            }
            showImpresiones(puntoWifi_aux.value)
        }

        const showImpresiones = (array) => {
            puntoWifi.value  = paginate(array, paginaInfo.value.pageSize, paginaInfo.value.page)
        }

        watch(mostrar_cantidad, () => {
            paginaInfo.value = {page: 1, pageSize: parseInt(mostrar_cantidad.value), prev: null, next: 0}
            // console.log(paginaInfo.value.pageSize)
            pageCont.value = Math.ceil(puntoWifi_aux.value.length/paginaInfo.value.pageSize)
            // console.log(pageCont.value)
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            showImpresiones(puntoWifi_aux.value)
        })

        watch(buscar, () => {
            filtro_buscador()
        })

        const filtro_buscador = () => {
            let arreglo_aux = []
            puntoWifi_aux.value = AllpuntoWifi.value
            if (buscar.value != '') {
                filtrado.value = true
                puntoWifi_aux.value.filter((punto)=>{
                    let dato =  punto.id.match(buscar.value)
                        || punto.precioPorDia.match(buscar.value)
                        // || punto.latitud.match(buscar.value)
                        // || punto.longitud.match(buscar.value)
                        || punto.nombre.toLowerCase().match(buscar.value.toLowerCase())
                        || punto.domicilio.toLowerCase().match(buscar.value.toLowerCase())
                        || punto.ciudad.toLowerCase().match(buscar.value.toLowerCase())
                        || punto.comentario.toLowerCase().match(buscar.value.toLowerCase())

                    if (dato != null) {
                        arreglo_aux.push(punto)
                    }

                })
                puntoWifi_aux.value = arreglo_aux
            } else {
                filtrado.value = false
                puntoWifi_aux.value = AllpuntoWifi.value
            }
            pageCont.value = Math.ceil(puntoWifi_aux.value.length/paginaInfo.value.pageSize)
            totalElement.value = puntoWifi_aux.value.length
            paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            showImpresiones(puntoWifi_aux.value)
        }

        const inicializar_clics = () => {
            clics.value.n1 = false
            clics.value.n2 = false
            clics.value.n3 = false
            clics.value.n4 = false
            clics.value.n5 = false
            clics.value.n6 = false
            clics.value.n7 = false
        }



        const ordenar = (tipo) => {
            inicializar_clics()
            switch (tipo) {
                case "id":
                    clics.value.n1 = true
                    puntoWifi_aux.value = ordenar_arreglo(tipo, ordenarId.value, puntoWifi_aux.value)
                    ordenarId.value = !ordenarId.value
                    break;

                case "nombre":
                    clics.value.n2 = true
                    puntoWifi_aux.value = ordenar_arreglo(tipo, ordenarNombre.value, puntoWifi_aux.value)
                    ordenarNombre.value = !ordenarNombre.value
                    break;

                case "domicilio":
                    clics.value.n3 = true
                    puntoWifi_aux.value = ordenar_arreglo(tipo, ordenarDomicilio.value, puntoWifi_aux.value)
                    ordenarDomicilio.value = !ordenarDomicilio.value
                    break;

                case "ciudad":
                    clics.value.n4 = true
                    puntoWifi_aux.value = ordenar_arreglo(tipo, ordenarCiudad.value, puntoWifi_aux.value)
                    ordenarCiudad.value = !ordenarCiudad.value
                    break;

                case "precioxdia":
                    clics.value.n5 = true
                    puntoWifi_aux.value = ordenar_arreglo(tipo, ordenarPrecio.value, puntoWifi_aux.value)
                    ordenarPrecio.value = !ordenarPrecio.value
                    break;

                case "comentario":
                    clics.value.n6 = true
                    puntoWifi_aux.value = ordenar_arreglo(tipo, ordenarComentario.value, puntoWifi_aux.value)
                    ordenarComentario.value = !ordenarComentario.value
                    break;

                case "anuncios_totales":
                    clics.value.n7 = true
                    puntoWifi_aux.value = ordenar_arreglo(tipo, ordenarAnuncios.value, puntoWifi_aux.value)
                    ordenarAnuncios.value = !ordenarAnuncios.value
                    break;

                default:
                    break;

            }

            showImpresiones(puntoWifi_aux.value)
        }

        const traerPuntoWiFi = () => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            watchEffect(() => {

                client.rawRequest(/* GraphQL */ `
                query($page: Int,$first:Int!){
                    puntowifis(first: $first, page: $page){
                        paginatorInfo{
                            count
                            currentPage
                            hasMorePages
                            lastPage
                            total
                        }
                        data{
                            id,
                            nombre,
                            domicilio,
                            latitud,
                            longitud,
                            precioxdia,
                            comentario,
                            hash,
                            municipio {
                                id,
                                nombre
                            },
                            anuncios{
                                nombre
                            }
                        }
                    }
                }`,
                {
                    page: parseInt(route.params.page),
                    first: mostrar_cantidad.value,
                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then((data) => {
                    // console.log(data)
                    puntoWifi.value = []
                    totalElement.value = data.data.puntowifis.paginatorInfo.total

                    data.data.puntowifis.data.forEach(element => {
                        // console.log(value.value.replace('hash', element.hash))
                        // console.log(value2.value.replace('hash', element.hash))
                        // console.log(value.value.replace('hash', element.hash) + '\n \n' + value2.value.replace('hash', element.hash))
                        let hash = value.value.replace('hash', element.hash) + '\n' + value2.value.replace('hash', element.hash)
                        puntoWifi.value.push({id:element.id, nombre: element.nombre, domicilio: element.domicilio, ciudad: element.municipio.nombre, anuncio: element.anuncios,
                        latitud: element.latitud + '', longitud: element.longitud + '' ,comentario: element.comentario+'',precioPorDia: element.precioxdia+ '', hash: hash, activo: false, configuracion: false, tildado: false, ver_anuncios: false})
                    })
                    // console.log(puntoWifi.value)


                }).catch(error => {
                    // console.log(error);
                })
            })

        }

        const eliminar = async (punto_id) => {
            let habilitado = await queryMe()
            if (habilitado) {
                eliminando(punto_id)
            } else {
                router.push({name: 'Login'})
            }
        }

        const eliminando = (punto_id) => {
            const client = new GraphQLClient(endpoint)

            client.rawRequest(/* GraphQL */ `
            mutation($id: ID!){
                eliminaPuntowifi(id: $id){
                    id,
                    nombre
                }
            }`,
            {
                id: punto_id
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {

                accion_exitosa.value = true
                paso_elim.value = true

                // console.log(data)
                // Busca el elemento y lo elimina del arreglo para que se vea dinamico para el cliente
                let cont = 0
                puntoWifi_aux.value.forEach(element => {
                    if (element.id == data.data.eliminaPuntowifi.id) {
                        puntoWifi_aux.value.splice(cont,1)
                    }
                    cont = cont +1
                })
                pageCont.value = Math.ceil(puntoWifi_aux.value.length/paginaInfo.value.pageSize)
                totalElement.value = puntoWifi_aux.value.length
                paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
                if (pageCont.value > 1) {
                    paginaInfo.value.next = paginaInfo.value.page +1
                }
                showImpresiones(puntoWifi_aux.value)

            })
            .catch(error => {
                let mensaje = error.message


                // console.log(error.response)

                // console.log(mostrar_nombre)

                if(mensaje.includes( 'No se puede eliminar este Punto. Tiene Anuncios relacionados')){
                    mostrar_error.value = !mostrar_error.value
                    mostrar_nombre.value = punto_id
                }else{
                    accion_exitosa.value = false
                    paso_elim.value = false

                }
            })
        }


        const modal = (item)=> {
            item.activo = !item.activo
        }


        const onSuccess = () => {
           copiado.value = true
           copiado_text.value = 'Script copiado'
        }

        const tildar = (punto) => {
            punto.tildado = !punto.tildado
        }

        const marcar_todo = () => {
            todo.value = !todo.value
            if(todo.value){

                puntoWifi_aux.value.forEach(element => {
                    element.tildado = true
                 })
            }else{
                puntoWifi_aux.value.forEach(element => {
                    element.tildado = false
                });
            }
            showImpresiones(puntoWifi_aux.value)
        }


        const filtro = () => {
            if (!filtrado.value) {
                limpiarFiltro()
            }
            filtrar.value = true
        }

        const limpiarFiltro = () => {
            datos_filtro.value = {id: '', nombre: '', domicilio: '', ciudad: '', latitud: '', longitud: '', precioxdia: '', correo: '', comentario: ''}
            tipo_filtro.value = {id: 'es', nombre: 'contiene', domicilio: 'contiene', ciudad: 'contiene', latitud: 'es', longitud: 'es', precioxdia: 'es', correo: 'contiene', comentario: 'contiene'}
            buscar.value = ''
        }

       const  filtro_avanzado = () => {
            let aux = []
            let punto_aux = AllpuntoWifi.value

            if (datos_filtro.value.id == '' && datos_filtro.value.nombre == '' && datos_filtro.value.domicilio == ''
            && datos_filtro.value.ciudad == '' && datos_filtro.value.precioxdia == '' && datos_filtro.value.comentario == '') {
                filtrado.value = false
            } else {
                filtrado.value = true
            }

            if (buscar.value != '') {
                filtro_buscador()
            }

            puntoWifi.value = punto_aux

            if (datos_filtro.value.id != '') {
                puntoWifi.value = filtrar_campo_numeral(datos_filtro.value.id, tipo_filtro.value.id, "id", puntoWifi.value, aux, "puntoswifi")
            }
            aux = []

            if (datos_filtro.value.nombre != '') {
                puntoWifi.value = filtrar_campo_texto(datos_filtro.value.nombre, tipo_filtro.value.nombre, "nombre", puntoWifi.value, aux, "puntoswifi")
            }
            aux = []

            if (datos_filtro.value.domicilio != '' || tipo_filtro.value.domicilio == 'vacio') {
                puntoWifi.value= filtrar_campo_texto(datos_filtro.value.domicilio, tipo_filtro.value.domicilio, "domicilio", puntoWifi.value, aux, "puntoswifi")
            }
            aux = []

            if (datos_filtro.value.ciudad != '') {
                puntoWifi.value = filtrar_campo_texto(datos_filtro.value.ciudad, tipo_filtro.value.ciudad, "ciudad", puntoWifi.value, aux, "puntoswifi")
            }
            aux = []

            if (datos_filtro.value.comentario != '' || tipo_filtro.value.comentario == 'vacio') {
                puntoWifi.value = filtrar_campo_texto(datos_filtro.value.comentario, tipo_filtro.value.comentario, "comentario", puntoWifi.value, aux, "puntoswifi")
            }
            aux = []

            // if (datos_filtro.value.latitud != '') {
            //     puntoWifi.value = filtrar_campo_numeral(datos_filtro.value.latitud, tipo_filtro.value.latitud, "latitud", puntoWifi.value, aux, "puntoswifi")
            // }
            // aux = []

            // if (datos_filtro.value.longitud != '') {
            //     puntoWifi.value = filtrar_campo_numeral(datos_filtro.value.longitud, tipo_filtro.value.longitud, "longitud", puntoWifi.value, aux, "puntoswifi")
            // }
            // aux = []

            if (datos_filtro.value.precioxdia != '') {
                puntoWifi.value = filtrar_campo_numeral(datos_filtro.value.precioxdia, tipo_filtro.value.precioxdia, "precioxdia", puntoWifi.value, aux, "puntoswifi")
            }

            pageCont.value = Math.ceil(puntoWifi.value.length/paginaInfo.value.pageSize)
            totalElement.value = puntoWifi.value.length
            paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            puntoWifi_aux.value = puntoWifi.value


            showImpresiones(puntoWifi_aux.value)

            filtrar.value = false
        }

        document.addEventListener('click', function(e) {
            let clic = e.target.getAttribute("id")

            if (clic != "mas-opciones")  {
                mas_opciones.value = false
                if (clic == 'modal-background') {
                    filtrar.value = false
                }
            }
        }, false)

        return {
            mostrar_nombre,
            ordenarId ,
            ordenarNombre ,
            ordenarDomicilio ,
            ordenarCiudad ,
            ordenarPrecio ,
            ordenarComentario ,
            ordenarAnuncios,
            clics,
            todo ,
            isMobile ,
            mostrar_cantidad ,
            puntoWifi ,
            puntoWifi_aux ,
            aux ,
            // value ,
            copiado ,
            copiado_text ,
            filtrar ,
            datos_filtro ,
            tipo_filtro ,
            buscar ,
            info ,
            route ,
            traerPuntoWiFi,
            eliminar,
            modal,
            onSuccess,
            tildar,
            marcar_todo,
            filtro,
            limpiarFiltro,
            filtro_avanzado,
            endpoint,
            mas_opciones ,
            eliminar_masivamente,
            eliminarPuntos,
            nextPage,
            prevPage,
            inicio,
            final,
            paginaInfo,
            pageCont,
            totalElement,
            ordenar,
            mostrar_error,
            verificarHabilitado,
            verificarHabilitadoParam,
            punto_elegido ,
            mostrar_advertencia2 ,
            opsMasivo ,
            cant_elegida ,
            eliminar_adv,
            confirmar,
            cancelar,
            eliminar_adv_masivo ,
            mostrar_advertencia,
            AllpuntoWifi,
            filtrado,
            mostrar_validacion ,
            preload,
            carga_exitosa,
            comprobar,
            comprobar_carga,
            comprobar_edicion,
            comprobar_edi,
            accion_exitosa,
            paso_elim,

        }
    },

    created(){
        this.traerPuntoWiFi()
        this.comprobar_carga()
        this.comprobar_edicion()
        this.puntoWifi_aux = this.puntoWifi
    },

}
</script>

<style scoped lang="scss">

#modal{
    border-radius: 6px;
}

.seleccionado{
    // background-color: #008575;
    background-color: #01AC97;
}
.seleccionado:hover{
    // background-color: #01AC97;
    background-color: #008575;
}

.deseleccionado:hover{
    background-color: #EDEDED;
}

.fondo-fila{
    background-color: rgb(223, 219, 219);
}

.circulo{
    list-style-type: disc;
}

.Color_sin_anuncio{
     background-color: rgb(216, 206, 74);
}
.Color_sin_anuncio:hover{
     background-color: rgb(216, 206, 74);
}

</style>